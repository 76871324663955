
































































import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";

interface RecommendedDimensionObject {
  category: object;
  item: object;
  slider: object;
}

const ConceptImageSizesProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptImageSizes extends ConceptImageSizesProps {
  public getLocale: any;
  translate!: Function;
  recommendedDimensions: RecommendedDimensionObject = {
    item: {
      height: "",
      width: "",
    },
    category: {
      height: "",
      width: "",
    },
    slider: {
      height: "",
      width: "",
    },
  };

  created() {
    if (this.data) {
      if (
        this.data["recommended-dimensions"] &&
        this.data["recommended-dimensions"]["category"]
      ) {
        this.recommendedDimensions.category = this.data[
          "recommended-dimensions"
        ]["category"];
      }

      if (
        this.data["recommended-dimensions"] &&
        this.data["recommended-dimensions"]["item"]
      ) {
        this.recommendedDimensions.item = this.data["recommended-dimensions"][
          "item"
        ];
      }

      if (
        this.data["recommended-dimensions"] &&
        this.data["recommended-dimensions"]["slider"]
      ) {
        console.log(this.data["recommended-dimensions"]["slider"]);
        this.recommendedDimensions.slider = this.data["recommended-dimensions"][
          "slider"
        ];
      }
    }
  }

  isLTR() {
    return this.getLocale === 'ar_SA' ? 'ltr' : '';
  }
}
